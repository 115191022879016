<template>
	<div>
		<ZyroButton
			v-for="({ colorSetId, colorSet }) in colorSetsList"
			:key="colorSetId"
			data-qa="builder-sidemenu-globalstyles-btn-colors"
			class="style-container style-container--hovered"
			:class="{ 'style-container--selected': isColorSetSelected(colorSetId) }"
			@click="handlePaletteClick(colorSet, colorSetId)"
		>
			<div class="style-container__content">
				<Edited :is-active="isWebsiteColorsEdited && isColorSetSelected(colorSetId)" />
				<div
					class="color-set color-set--no-padding"
					:class="{ 'color-set--no-margin': isColorSetSelected(colorSetId) }"
				>
					<div
						v-for="displayColor in DISPLAY_COLORS"
						:key="`${colorSetId}${displayColor}`"
						:style="{ backgroundColor: colorSet[displayColor] }"
						class="color-set__color"
					/>
				</div>
				<EditStyleSeparator
					:title="$t('builder.userStyles.colors.customizeColorPalette')"
					:is-active="isColorSetSelected(colorSetId)"
				/>
			</div>
		</ZyroButton>
	</div>
</template>

<script>
import isEqual from 'lodash.isequal';
import {
	mapState,
	mapMutations,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import EditStyleSeparator from '@/components/builder-drawers/drawers/partials/stylesDrawer/Misc/EditStyleSeparator.vue';
import Edited from '@/components/builder-drawers/drawers/partials/stylesDrawer/Misc/Edited.vue';
import { useColorSetsLibrary } from '@/components/builder-drawers/drawers/partials/stylesDrawer/colors/use/useColorSetsLibrary';
import { USER_STYLES_COLORS_PALLET_EDIT } from '@/store/builder/constants/drawerPages';
import { USER_STYLES_DRAWER } from '@/store/builder/constants/drawers';
import { USER_COLORS_RESET_ALL_CHANGES_MODAL } from '@/store/builder/constants/modals';
import {
	mapActionsGui,
	CHANGE_DRAWER_PAGE,
	OPEN_MODAL,
} from '@/store/builder/gui';

const DISPLAY_COLORS = [
	'primary',
	'secondary',
	'accent-1',
	'accent-2',
	'light',
];

export default {
	components: {
		Edited,
		EditStyleSeparator,
	},
	setup() {
		const {
			colorSetsList,
			setColorSet,
			currentColorSet,
			addColorSetToList,
			uneditedColorSetsList,
			updateColorSetListWithWebsiteColors,
			addTemplateColorSetToList,
			currentColorSetId,
		} = useColorSetsLibrary();

		return {
			isEqual,
			setColorSet,
			colorSetsList,
			currentColorSet,
			addColorSetToList,
			uneditedColorSetsList,
			updateColorSetListWithWebsiteColors,
			addTemplateColorSetToList,
			currentColorSetId,
			DISPLAY_COLORS,
		};
	},
	computed: {
		...mapState(['website']),
		websiteColors: ({ website }) => website.styles.colors,
		isWebsiteColorsEdited: ({
			websiteColors,
			uneditedColorSetsList,
			currentColorSet,
		}) => {
			const currentUneditedColorSet = uneditedColorSetsList
				.find(({ colorSetId }) => colorSetId === currentColorSet.colorSetId) ?? {};

			return !isEqual(websiteColors, currentUneditedColorSet.colorSet);
		},
	},
	mounted() {
		this.updateColorSetListWithWebsiteColors();
	},
	methods: {
		...mapMutations(['setStyleProperties']),
		...mapActionsGui({
			openModal: OPEN_MODAL,
			changeDrawerPage: CHANGE_DRAWER_PAGE,
		}),
		handleColorSetting(colorSet, colorSetId) {
			if (this.isWebsiteColorsEdited) {
				this.openModal({
					name: USER_COLORS_RESET_ALL_CHANGES_MODAL,
					settings: {
						colorSet,
						colorSetId,
					},
				});
			} else {
				this.setColorSet(colorSet, colorSetId);

				EventLogApi.logEvent({
					eventName: 'builder.styles.replace_color_palette',
					eventProperties: { colorSetId },
				});
			}
		},
		handlePaletteClick(colorSet, colorSetId) {
			return isEqual(colorSet, this.websiteColors)
				? this.goToPaletteEdit()
				: this.handleColorSetting(colorSet, colorSetId);
		},
		goToPaletteEdit() {
			this.changeDrawerPage({
				drawerKey: USER_STYLES_DRAWER,
				pageKey: USER_STYLES_COLORS_PALLET_EDIT,
			});
		},
		isColorSetSelected(colorSetId) {
			return colorSetId === this.currentColorSetId;
		},
	},
};
</script>

<style scoped lang="scss">
@import './Colors';
@import '@/components/builder-drawers/drawers/partials/stylesDrawer/UserStyles.scss';
</style>
